export const defaultSerializers = {
  marks: {
    italic: props => <em>{props.children}</em>,
  },
  types: {
    embebedCode: props => (
      <div dangerouslySetInnerHTML={{ __html: props.node.code }} />
    ),
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    italic: ({ node }) => <em>{node.text}</em>,
    reference: () => <div></div>,
  },
}
