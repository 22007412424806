import React, { FC } from "react"
import LangContext from "@contexts/lang"
import { SanityTestimonialSection, SanityTestimony } from "@utils/types"
import s from "./TestimonialSection.module.scss"
/* import Testimonial from "./Testimonial" */
import { BaseBlockContent } from "../../globals/baseBlockContent/baseBlockContent"
import Img from "gatsby-image"
import marcos from "../../../images/marcos.png"
import k from "../../../images/gatsby-icon.png"
const TestimonialSection: FC<{
  testimonialsSection: SanityTestimonialSection
  lite?: boolean
}> = ({ testimonialsSection, lite = false }) => {
  const { lang } = React.useContext(LangContext)
  const [currentSlide, setCurrentSlide] = React.useState(
    testimonialsSection.testimonies[0]
  )

  const handleClick = (testimony: SanityTestimony) => {
    setCurrentSlide(testimony)
  }

  return (
    <div
      className={`${s.testimonialsWrapper} ${lang === "ar" &&
        s.flipped} ${lite && s.liteBg}`}
    >
      <div className={s.testimonialSection}>
        {currentSlide && (
          <div className={s.customCarouselItem}>
            <div className={s.description}>
              <div className={`${s.content} ${lite && s.white}`}>
                <BaseBlockContent blocks={currentSlide.testimonial[lang]} />
              </div>
              <div className={s.logoContainer}>
                <div className={s.storeLogo}>
                  <Img
                    className={s.profilePic}
                    fluid={currentSlide.storeLogo.asset.fluid}
                  />
                </div>
                <div>
                  <p className={`${s.reviewerTitle} ${lite && s.white}`}>
                    {currentSlide.reviewerTitle[lang]}
                  </p>
                  <p className={`${s.reviewerName} ${lite && s.white}`}>
                    {currentSlide.reviewerName[lang]}
                  </p>
                </div>
              </div>
              <div className={s.dotsWrapper}>
                <div className={s.dots}>
                  {testimonialsSection.testimonies.map((testimony, i) => {
                    const className =
                      testimony.reviewerName === currentSlide.reviewerName
                        ? s.active
                        : ""
                    return (
                      <div
                        key={i}
                        className={`${s.dot} ${className}`}
                        onClick={() => handleClick(testimony)}
                      ></div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={`${s.image} ${lite && s.liteImage}`}>
              <div className={`${s.square} ${lite && s.liteBg}`}>
                <Img
                  className={s.profilePic}
                  fluid={currentSlide.reviewerImage.asset.fluid}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TestimonialSection
