import React from "react"
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import { SanityDescriptionPerk } from "@utils/types"
import s from "./descriptionPerk.module.scss"
import CTA from "../cta"
import useResizeWidth from "@hooks/useResizeWidth"
import FeatureAdd from "@images/featureAdd.svg"
import FeatureMinus from "@images/featureMinus.svg"

type Props = {
  descriptionPerk: SanityDescriptionPerk
  id: number
  expanded: boolean
  onExpanded(index: number): void
  className?: string
  mainColor?: string
  secondaryColor?: string
  backgroundBlue?: boolean
  lite?: boolean
}

const DescriptionPerk: React.FC<Props> = ({
  descriptionPerk,
  id,
  expanded,
  onExpanded,
  className,
  mainColor,
  secondaryColor,
  backgroundBlue=false,
  lite = false
}) => {
  const { lang } = React.useContext(LangContext)
  const { width } = useResizeWidth()
  const { headline, subheadline, cta, image } = descriptionPerk
  const fillColor = backgroundBlue ? "black":"#234CFB"
  return (
    <>
      <div
        className={`${s.descriptionPerkComp} ${expanded &&
          s.expanded} ${className} ${lite && s.white} `}
        style={{ color: mainColor, borderColor: mainColor }}
      >
        <div className={s.mobileImage}>
            {image && expanded && (
              <div className={s.mobileImage}>
                  <Img fluid={image.asset?.fluid} className={s.image} />
              </div>
            )}
        </div>
        {headline && (
          <div
            className={s.featureContainer}
            onClick={() => (expanded || onExpanded(id - 1))}
          >
            {expanded ? <FeatureMinus  fill={fillColor}/> : <FeatureAdd fill={fillColor}/>}
            <h4 className={`${s.headline} ${lang === "ar" && s.headlineArabic} ${lite && s.white}`}>
              {headline[lang]}
            </h4>
          </div>
        )}
        {expanded && (
          <>
            {subheadline && (
              <p
                className={`${s.subheadline} ${lang === "ar" &&
                  s.subheadlineArabic}`}
              >
                {subheadline[lang]}
              </p>
            )}
            {cta?.href && (
              <CTA
                cta={cta}
                withArrow
                className={s.cta}
                color={secondaryColor}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default DescriptionPerk
