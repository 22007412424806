import React, { FC } from "react"
/* import { graphql, useStaticQuery } from "gatsby" */
import Img from "gatsby-image"
import LangContext from "@contexts/lang"
import s from "./HeroSection.module.scss"
import FreeTrialForm from "../../common/FreeTrialForm"
import RestaurantsSection from "../RestaurantsSection"
import { SanityBusinessSection, SanityHero } from "@utils/types"
import useMounted from "@hooks/useMounted"
import logo from "../../../images/logow.png"
import { navigate } from "gatsby-plugin-intl"
import LanguageButton from "../../Layout/Components/Header/LanguageButton"
import formatMessage from "@utils/texts"

const HeroSection: FC<{
  sanityHero: SanityHero
  businessSection: SanityBusinessSection
}> = ({ sanityHero, businessSection }) => {
  const { lang } = React.useContext(LangContext)
  const hasMounted = useMounted()

  const {
    title,
    subtitle,
    registrationForm,
    chips,
    image,
    video,
    commission,
  } = sanityHero
  const partialTitle = title[lang].split("|")

  return (
    <>
      <div className={s.bg}>
        <div className={s.alignFlex}>
          <img src={logo}></img>
          <LanguageButton />
        </div>
        <div className={`${s.heroSection} ${lang === "ar" && s.flipped}`}>
          <div className={s.texts}>
            <div className={s.titleWrapper}>
              <div className={s.titleContainer}>
                <h1 className={s.title}>
                  {formatMessage({ id: "payAsYouGo", lang })}
                </h1>
              </div>
            </div>
            <h4 className={s.description}>
              {formatMessage({ id: "cashBack", lang })}
            </h4>
            <button
              className={s.button}
              onClick={() => {
                navigate("/register")
              }}
            >
              {formatMessage({ id: "register", lang })}
            </button>
          </div>
        </div>
      </div>
      <div className={s.chargeContainer}>
        <span className={s.smTitle}>
          {" "}
          {formatMessage({ id: "charged", lang })}
        </span>
        <h2 className={s.midTitle}>
          {" "}
          {formatMessage({ id: "payAsYouGo", lang })}
        </h2>
        <p>{formatMessage({ id: "chargeDetails", lang })}</p>
      </div>
    </>
  )
}

export default HeroSection
