import React from "react"
import Img from "gatsby-image"
import { SanityPromotion, SanityImage } from "@utils/types"
import LangContext from "@contexts/lang"
import CTA from "@components/common/cta"
import s from "./promotionSection.module.scss"
import FreeTrialForm from "../FreeTrialForm"
import DescriptionPerk from "../descriptionPerk"
import useResizeWidth from "@hooks/useResizeWidth"

const PromotionSection: React.FC<{
  imageComponent?: SanityImage
  promotionSection: SanityPromotion
  className?: string
  lite?: boolean
}> = ({ promotionSection, className, imageComponent, lite = false }) => {
  const { lang } = React.useContext(LangContext)
  const [expanded, setExpanded] = React.useState(0)
  const { width } = useResizeWidth()

  const {
    heading,
    title,
    slug,
    descriptionPerks,
    mainCTA,
    inverted,
    registrationForm,
  } = promotionSection

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? expanded : index)
  }

  if (lite) {
    if (typeof document !== "undefined") {
      var header = document.querySelector("header")
      if (header) {
        header.classList.add(s.hide)
      }
    }
  }

  return (
    <section
      id={slug?.current}
      className={`${s.promotionOverlay} ${className} ${lang === "ar" &&
        s.flipped} ${lite && s.liteBg} `}
    >
      {imageComponent && imageComponent}
      <div className={`${s.promotionSection}  ${inverted && s.inverted}`}>
        <div className={`${s.subcontent} ${inverted && lite && s.litePadding}`}>
          {heading && <p>{heading[lang]}</p>}
          {title && (
            <h2 className={`${s.title} ${lite && s.white}`}>{title[lang]}</h2>
          )}

          {descriptionPerks?.length && (
            <div className={s.descriptionSection}>
              {descriptionPerks.map((dp, i) => (
                <DescriptionPerk
                  descriptionPerk={dp}
                  key={i}
                  id={i + 1}
                  expanded={expanded === i}
                  onExpanded={handleExpanded}
                  lite={lite && true}
                />
              ))}
            </div>
          )}
        </div>
        {width > 768 && (
          <div className={s.imageSection}>
            {descriptionPerks[expanded].image?.asset && (
              <Img fluid={descriptionPerks[expanded].image?.asset?.fluid} />
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default PromotionSection
