import React from "react"
import { Link } from "@components/common/LangLink"
import { HiOutlineArrowRight } from "react-icons/hi"
import LangContext from "@contexts/lang"
import { SanityCta } from "@utils/types"
import s from "./cta.module.scss"

type Props = {
  cta: SanityCta
  withArrow?: boolean
  color?: string
  backgroundColor?: string
  className?: string
  params?: string
}

const CTA: React.FC<Props> = ({
  cta,
  withArrow,
  color,
  backgroundColor,
  className = "",
  params,
}) => {
  const { lang } = React.useContext(LangContext)
  const { style, label, href } = cta

  if (!href) return <></>

  const to = params ? `${href}/${params}` : href

  const linkClassName = s[style]
  return (
    <Link
      className={`${s.cta} ${linkClassName || ""} ${className}`}
      to={to}
      style={{ backgroundColor, color }}
    >
      {label[lang]}
      <span className={`${s.arrow} ${lang === "ar" && s.flipped}`}>
        {withArrow && <HiOutlineArrowRight />}
      </span>
    </Link>
  )
}

export default CTA
