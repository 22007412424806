export default function(features, country, apiCall) {
  let featureApiResponce = features.content.filter(
    c => c.__typename === apiCall
  )

  let countryContentCondition =
    featureApiResponce.filter(j => j.countriesContent.includes(country))
      .length > 0

  let filteredContentOnCountry = featureApiResponce.filter(j =>
    countryContentCondition
      ? j.countriesContent.includes(country)
      : j.countriesContent.includes("KW")
  )

  return filteredContentOnCountry
}
