import React from "react"
import { SanityRawText } from "@utils/types"
import BlockContent from "@sanity/block-content-to-react"
import { defaultSerializers } from "./defaultSerializers"

type Props = {
  blocks: SanityRawText[]
  serializers?
  className?: string
}

export const BaseBlockContent: React.FC<Props> = ({
  blocks,
  serializers,
  className,
}) => {
  const serializersDict = serializers || defaultSerializers
  
  return (
    <BlockContent
      serializers={serializersDict}
      blocks={blocks}
      className={className}
    />
  )
}
